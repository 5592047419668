
import axios from 'axios';
import config from '../lib/config';

const MAPBOX_API_URL = config?.MAPBOX_API_URL;

const MAPBOX_ACCESS_TOKEN = config?.MAPBOX_TOKEN;

export const reverseGeocode = async (lat: number, lng: number): Promise<string> => {
  try {
    const response = await axios.get(`${MAPBOX_API_URL}/${lng},${lat}.json`, {
      params: {
        access_token: MAPBOX_ACCESS_TOKEN,
        limit: 1,
      },
    });

    const address = response.data.features[0]?.place_name || 'Address not found';
    return address;
  } catch (error) {
    console.error('Error fetching address:', error);
    return 'Address not found';
  }
};


export const searchAddress = async (query: string) => {
  try {
    const response = await axios.get(`${MAPBOX_API_URL}/${encodeURIComponent(query)}.json`, {
      params: {
        access_token: MAPBOX_ACCESS_TOKEN,
        autocomplete: true,
        limit: 8
      },
    });

    return response.data.features; // Array of place suggestions
  } catch (error) {
    console.error('Error fetching address data from Mapbox:', error);
    return [];
  }
};