import React, { useState, useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Option } from '../../types/dataType';
import checkBoxActive from '../../assets/icons/checkBoxActive.svg'
import checkBoxUnactive from '../../assets/icons/checkBoxUnactive.svg'

interface ProductDetailRadioButtonProps {
    item: Option;
    active: boolean;
    setSelectedAction: (value: Option) => void;
}


const ProductDetailRadioButton: React.FC<ProductDetailRadioButtonProps> = ({
    item, active, setSelectedAction
}) => {
    const [status, setStatus] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        setStatus(active);
    }, [active]);

    return (
        <div className='productDetailRadioButton-inner' onClick={() => {
            setSelectedAction(item)
            setStatus(!status)
        }
        }>
            <div className='productDetailRadioButton-inner-label'>
                {t(`${item?.name}`)}
            </div>
            <div className='productDetailRadioButton-inner-value'>
                {item?.price === 0 ? t('Free') : `+ ${item?.price.toFixed(2)}`}
            </div>
            <div className='productDetailRadioButton-inner-button'>
                {status ? <img src={checkBoxActive} /> :
                    <img src={checkBoxUnactive} />}
            </div>
        </div>
    );
};

export default ProductDetailRadioButton;
