import React, { useEffect, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Company } from '../../types/dataType';
import rightIcon from '../../assets/icons/right.svg';


interface ContainerProps {
    children: React.ReactNode;
    data?: Company;
    open: () => void
}

const OutletBodyContainer: React.FC<ContainerProps> = ({ children, data, open }) => {
    const { t } = useTranslation();
    const [companyData, setCompanyData] = useState<Company>();


    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data]);

    return (
        <div className='outletBodyContainer-content'>
            <div className='outletBodyContainer-content-header'>
                <div className='outletBodyContainer-content-header-inner'>
                    <div className='outletBodyContainer-content-header-logo'>
                        <img src={companyData?.main_image || require('../../assets/images/logo.png')} />
                    </div>
                    <div className='outletBodyContainer-content-header-titleContainer'>
                        <div className='outletBodyContainer-content-header-titleContainer-left'>
                            <div className='outletBodyContainer-content-header-titleContainer-left-title'>
                                {companyData?.display_name ? t(`${companyData?.display_name}`) : ''}
                            </div>

                            <div className='outletBodyContainer-content-header-titleContainer-left-type'>
                                {companyData?.description ? t(`${companyData?.description}`) : ''}
                            </div>

                        </div>
                        <div className='outletBodyContainer-content-header-titleContainer-right' onClick={() => {
                            open()
                        }}>
                            <img src={rightIcon} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='outletBodyContainer-content-body'>
                {children}
            </div>
        </div>
    );
};

export default OutletBodyContainer;
