import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';
import ProductBodyContainer from '../../components/ProductBodyContainer';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { useStore } from '../../contexts/StoreContent'
import { useTranslation } from 'react-i18next';
import ProductContainer from '../../components/ProductContainer';
import CartComponent from '../../components/CartComponent';
import { Item, Store, Company, Images } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import SelectedProductDetailModal from '../../components/SelectedProductDetailModal';
import OutletDetailModal from '../../components/OutletDetailModal';
import SearchProductModal from '../../components/SearchProductModal';
import { Link, Element, Events, scroller } from 'react-scroll';

type ListItemType = {
    [key: string]: Item[];
};

const groupItemsByCategory = (items: Item[] | undefined) => {
    return items?.reduce((acc, item) => {
        item?.category_list?.forEach((cat) => {
            const displayName = cat?.display_name;
            if (displayName) {
                if (!acc[displayName]) {
                    acc[displayName] = [];
                }
                acc[displayName].push(item);
            }
        });
        return acc;
    }, {} as Record<string, typeof items>);
};

const ProductPage = () => {
    const [outletModal, setOutletModal] = useState<boolean>(false);
    const [openSearchItemModal, setOpenSearchItemDetailModal] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [displayItem, setDisplayItem] = useState<Item[]>([]);
    const [selectedStore, setSelectedStore] = useState<Store | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
    const [categories, setCategories] = useState<string[]>([]);
    const [companyData, setCompanyData] = useState<Company>();
    const [openSelectedItemModal, setOpenSelectedItemDetailModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const { storeId } = useParams();
    const { t } = useTranslation();
    const { setToCart } = useCart();
    const { getStoreDetails } = useStore()
    const [listItem, setListItem] = useState<ListItemType>({});
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});


    useEffect(() => {
        if (storeId) {
            getStoreDetails(storeId).then((selectedStoreData) => {
                if (selectedStoreData) {
                    // console.log('selectedStoreData', selectedStoreData)
                    setSelectedStore(selectedStoreData);
                    const products = selectedStoreData?.product_list;
                    if (!_.isEmpty(products)) {
                        const data = groupItemsByCategory(products) || [];
                        const allCategories = Object.keys(data);
                        // console.log('categories', data)
                        setCategories(allCategories);
                        setListItem(data as ListItemType);
                        if (allCategories.length > 0) {
                            setSelectedType(allCategories[0]);
                        }
                    }
                }
            }).catch((error) => {
                console.error('Error fetching store details:', error);
            });
        }
    }, [storeId]);

    const openProductDetail = (product: Item) => {
        if (_.isEmpty(product?.option_group_list)) {
            setToCart(product, selectedStore, [], 1);
        } else {
            navigate(`/productDetail/${product?._id}`)
        }
    }

    const openProductInCart = (product: Item) => {
        if (_.isEmpty(product?.option_group_list)) {
            setToCart(product, selectedStore, [], -1);
        } else {
            setSelectedItem(product);
            setOpenSelectedItemDetailModal(true)
        }
    }

    const imageBgDisplay = (images?: Images[]) => {
        if (images && !_.isEmpty(images)) {
            return <img src={images[0]?.images_uri || ''} alt="Store Image" />
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [categories]);

    const handleSetActive = (to: string) => {
        setSelectedType(to);
    };

    useEffect(() => {
        if (containerRef.current && selectedType && itemRefs.current[selectedType]) {
            const container = containerRef.current;
            const item = itemRefs.current[selectedType];

            // Get the bounding rect of the item
            const itemRect = item.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            // Calculate the scroll position
            const scrollLeft = itemRect.left - containerRect.left + container.scrollLeft;

            // Scroll to the item
            container.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    }, [selectedType]);


    return (
        <div className='productContainer'>
            <Header title={selectedStore?.display_name} back search info scrollWithColor
                searhAction={() =>
                    setOpenSearchItemDetailModal(true)
                }
                infoAction={() =>
                    setOutletModal(true)}
                backAction={() => navigate('/store')}
            />
            <div className='productContainer-background'>
                {imageBgDisplay(selectedStore?.images)}
            </div>
            {selectedStore && (
                <ProductBodyContainer data={selectedStore} action={() =>
                    setOutletModal(true)}>
                    <div className='productContainer-type' ref={containerRef}>
                        {_.map(categories, (type, index) => (
                            <div className='productContainer-type-item' key={index}
                                ref={el => itemRefs.current[type] = el}>
                                <Link
                                    to={type}
                                    smooth={true} duration={100} spy={true}
                                    isDynamic={true}
                                    onSetActive={handleSetActive} offset={-120}
                                    activeStyle={{ textDecoration: 'none' }}
                                >
                                    <div className={`productContainer-type-item-label ${selectedType === type ? 'active' : ''}`}>
                                        {t(`${type}`)}
                                    </div>
                                </Link>
                                {selectedType === type && (
                                    <div className='productContainer-type-item-borderBottom'></div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='productContainer-content'>
                        {_.map(categories, (category, index) => (
                            <div key={index}>
                                <Element name={category} >
                                    {_.map(listItem[category], (item, index) => (
                                        <div key={index}>
                                            <ProductContainer item={item} index={index}
                                                addItem={openProductDetail}
                                                removeItem={openProductInCart} />
                                        </div>
                                    ))}
                                </Element>
                            </div>
                        ))}
                    </div>
                </ProductBodyContainer>
            )}
            <CartComponent />
            <SearchProductModal modal={openSearchItemModal}
                store={selectedStore} setModal={setOpenSearchItemDetailModal}
                data={displayItem} />
            <SelectedProductDetailModal modal={openSelectedItemModal}
                setModal={setOpenSelectedItemDetailModal}
                data={selectedItem} />
            {!_.isEmpty(companyData) && (
                <OutletDetailModal modal={outletModal} setModal={setOutletModal}
                    data={companyData} />
            )}
        </div>
    );
};

export default ProductPage;
