import React, { useEffect, useState } from 'react';
import './index.scss';
import OutletBodyContainer from '../../components/OutletBodyContainer';
import * as _ from 'lodash';
import Header from '../../components/Header';
import ItemContainer from '../../components/ItemContainer';
import SearchStoreModal from '../../components/SearchStoreModal';
import { useNavigate } from 'react-router-dom';
import { Company } from '../../types/dataType';
import OutletDetailModal from '../../components/OutletDetailModal';
import { useStore } from '../../contexts/StoreContent'

const StorePage = () => {
    const [modal, setModal] = useState<boolean>(false);
    const [outletModal, setOutletModal] = useState<boolean>(false);
    const [companyData, setCompanyData] = useState<Company>();
    const navigate = useNavigate();
    const { outletData } = useStore()


    useEffect(() => {
        if (outletData) {
            setCompanyData(outletData?.company)
        }
    }, [outletData]);

    return (
        <div className='storeContainer'>
            <Header title={companyData?.display_name} back search scrollWithColor
                searhAction={() => setModal(true)} backAction={() => navigate('/outlet')} />
            <div className='storeContainer-background'>
                <img src={companyData?.main_image || require('../../assets/images/outletBg.png')} />
            </div>
            {companyData && (
                <>
                    <OutletBodyContainer data={companyData} open={() => setOutletModal(true)}>
                        <div className='storeContainer-content'>
                            {_.map(companyData?.store_list, (item, index) => (
                                <div key={index} className='storeContainer-content-item' onClick={() =>
                                    navigate(`/product/${item?._id}`)}>
                                    <ItemContainer item={item} />
                                </div>
                            ))}
                        </div>
                    </OutletBodyContainer>
                    <SearchStoreModal modal={modal} setModal={setModal} data={companyData?.store_list} />
                    {!_.isEmpty(companyData) && (
                        <OutletDetailModal modal={outletModal} setModal={setOutletModal} data={companyData} />
                    )}
                </>
            )}
        </div>
    );
};

export default StorePage;
