import React, { useEffect } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import * as _ from 'lodash';
import { useLocation } from 'react-router-dom';
import homeIcon from '../../assets/icons/home.svg';
import shopIcon from '../../assets/icons/shop.svg';
import billIcon from '../../assets/icons/bill.svg';
import profileIcon from '../../assets/icons/profile.svg';

interface FooterProps {
    display: boolean;
}

const Footer: React.FC<FooterProps> = ({ display }) => {
    const navigate = useNavigate();
    const { outletData } = useStore()
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (display) {
        return (
            <div className='footerContainer'>
                <div className='footerContainerInner'>
                    <div className='footerContainerInner-item' onClick={() => {
                        if (!_.isEmpty(outletData)) {
                            navigate('/store')
                        }
                    }}>
                        <img src={homeIcon} />
                    </div>
                    <div className='footerContainerInner-item' onClick={() => { navigate('/cart') }}>
                        <img src={shopIcon} />
                    </div>
                    <div className='footerContainerInner-item'>
                        <img src={billIcon} />
                    </div>
                    <div className='footerContainerInner-item' onClick={() => { navigate('/account') }}>
                        <img src={profileIcon} />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Footer;
