import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContent';
import { CartProvider } from './contexts/CartContent';
import { StoreProvider } from './contexts/StoreContent';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from './lib/apollo';
// import LoginPage from './pages/';
import LandingPage from './pages/LandingPage';
import BodyContainer from './components/BodyContainer';
import HomePage from './pages/HomePage';
import OutletPage from './pages/OutletPage';
import StorePage from './pages/StorePage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import CheckOutPage from './pages/CheckOutPage';
import OrderPage from './pages/OrderPage';
import ProductDetailPage from './pages/ProductDetailPage';
import EditOrderItemPage from './pages/EditOrderItemPage';
import Loading from './components/LoadingScreen';
import QR from './pages/QR';
import { LoadingProvider } from './contexts/LoadingContent';
import AccountPage from './pages/AccountPage';

function App() {

    const client = createApolloClient();

    return (
        <ApolloProvider client={client}>
            <Router>
                <LoadingProvider>
                    <AuthProvider>
                        <StoreProvider>
                            <CartProvider>
                                <Loading />
                                <BodyContainer>
                                    <Routes>
                                        <Route path="/home" element={<HomePage />} />
                                        <Route path="/qr/:qrCode" element={<QR />} />
                                        <Route path="/outlet" element={<OutletPage />} />
                                        <Route path="/store" element={<StorePage />} />
                                        <Route path="/account" element={<AccountPage />} />
                                        <Route path="/product/:storeId" element={<ProductPage />} />
                                        <Route path="/productDetail/:productId" element={<ProductDetailPage />} />
                                        <Route path="/editOrderItemPage/:productId/:cartItemId" element={<EditOrderItemPage />} />
                                        <Route path="/cart" element={<CartPage />} />
                                        <Route path="/checkOut" element={<CheckOutPage />} />
                                        <Route path="/order" element={<OrderPage />} />
                                        <Route path="/" element={<LandingPage />} />
                                    </Routes>
                                </BodyContainer>
                            </CartProvider>
                        </StoreProvider>
                    </AuthProvider>
                </LoadingProvider>
            </Router>
        </ApolloProvider>
    );
}

export default App;
