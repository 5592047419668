import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useFooterVisibility = () => {
  const location = useLocation();
  const path = location.pathname;
  const pageName = path.split('/').pop();
  const [footer, setFooter] = useState(false);

  const withFooter = ['home', 'outlet', 'store', 'account'];

  useEffect(() => {
    if (withFooter.includes(pageName || '')) {
      setFooter(true);
    } else {
      setFooter(false);
    }
  }, [pageName]);

  return footer;
};

export default useFooterVisibility;