import React from 'react';
import './index.scss';
import { useCart } from '../../contexts/CartContent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CartComponent: React.FC = () => {
    const { t } = useTranslation();
    const { cart, totalAmount } = useCart();
    const navigate = useNavigate();

    return (
        <div className='cartComponent'>
            <div className='cartComponent-inner'>
                <div className='cartComponent-inner-left'>
                    <div className='cartComponent-inner-left-qty'>
                        {cart?.length} {t('Items')}
                    </div>
                    <div className='cartComponent-inner-left-total'>
                        RM {totalAmount.toFixed(2)}
                    </div>
                </div>
                <div className='cartComponent-inner-right'>
                    <div className='cartComponent-inner-right-btn' onClick={() => { navigate('/cart') }}>
                        View cart
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartComponent;
