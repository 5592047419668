import React from 'react';
import { useLoading } from '../../contexts/LoadingContent';
import './index.scss'

const LoadingScreen = () => {
  const loadingStatus = useLoading()?.loadingStatus;

  if (loadingStatus) {
    return (
      <div className={`loading-spinner ${loadingStatus ? 'active' : ''}`}>
        <div className="animated-image">
          <img src={require('../../assets/images/logo.png')} />
          <img className='animated-image-text' src={require('../../assets/images/logoText.png')} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingScreen;
