import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { Outlet, Company } from '../../types/dataType';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import blackClose from '../../assets/icons/blackClose.svg';
import infoBlue from '../../assets/icons/infoBlue.svg';
import locationIcon from '../../assets/icons/location.svg';
import timeIcon from '../../assets/icons/time.svg';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ModalComponent from '../ModalComponent';


const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;



interface OutletDetailModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    data: Company;
}


// Functional component with props type
const OutletDetailModal: React.FC<OutletDetailModalProps> = ({
    modal = false,
    setModal,
    data,
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState<Company>();

    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data]);

    useEffect(() => {
        setOpenModal(modal);
    }, [modal]);

    const handleClose = () => {
        setModal(false)
        setOpenModal(false)
    };

    return (
        <>
            <ModalComponent modal={openModal} setModal={setOpenModal} fullHeight>
                <div className='outletDetailModal'>
                    <div className='outletDetailModal-modal'>
                        <div className='outletDetailModal-modal-header'>
                            <div className='outletDetailModal-modal-header-icon' onClick={() => handleClose()}>
                                <img src={blackClose} />
                            </div>
                            <div className='outletDetailModal-modal-header-text'>
                                {t(`${companyData?.display_name}`)}
                            </div>
                        </div>
                        <div className='outletDetailModal-modal-body'>
                            <div className='outletDetailModal-modal-body-inner'>
                                <div className='outletDetailModal-modal-body-inner-icon'>
                                    <img src={infoBlue} />
                                </div>
                                <div className='outletDetailModal-modal-body-inner-content'>
                                    <div className='outletDetailModal-modal-body-inner-content-label'>
                                        {t('Information')}
                                    </div>
                                    {companyData?.email && (
                                        <div className='outletDetailModal-modal-body-inner-content-address'>
                                            {companyData?.email}
                                        </div>
                                    )}
                                    {companyData?.phone && (
                                        <div className='outletDetailModal-modal-body-inner-content-address'>
                                            {companyData?.phone}
                                        </div>
                                    )}
                                    {companyData?.gps && companyData.gps.length === 2 && (
                                        <div className='outletDetailModal-modal-body-inner-content-map'>
                                            <MapContainer center={[companyData?.gps[0], companyData?.gps[1]]} zoom={13} style={{ height: 260, width: "100%", borderRadius: 8 }}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[companyData?.gps[0], companyData?.gps[1]]}>
                                                    <Popup>
                                                        {t(`${companyData?.display_name}`)}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='outletDetailModal-modal-body-inner'>
                                <div className='outletDetailModal-modal-body-inner-icon'>
                                    <img src={locationIcon} />
                                </div>
                                <div className='outletDetailModal-modal-body-inner-content'>
                                    <div className='outletDetailModal-modal-body-inner-content-label'>
                                        {t('Address')}
                                    </div>
                                    <div className='outletDetailModal-modal-body-inner-content-address'>
                                        {companyData?.address}
                                    </div>
                                    {companyData?.gps && companyData.gps.length === 2 && (
                                        <div className='outletDetailModal-modal-body-inner-content-map'>
                                            <MapContainer center={[companyData?.gps[0], companyData?.gps[1]]} zoom={13} style={{ height: 260, width: "100%", borderRadius: 8 }}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[companyData?.gps[0], companyData?.gps[1]]}>
                                                    <Popup>
                                                        {t(`${companyData?.display_name}`)}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!_.isEmpty(companyData?.openingHour) && (
                                <div className='outletDetailModal-modal-body-inner mt-3'>
                                    <div className='outletDetailModal-modal-body-inner-icon'>
                                        <img src={timeIcon} />
                                    </div>
                                    <div className='outletDetailModal-modal-body-inner-content'>
                                        <div className='outletDetailModal-modal-body-inner-content-label'>
                                            {t('Opening Hours')}
                                        </div>
                                        <div className='outletDetailModal-modal-body-inner-content-time'>
                                            {_.map(companyData?.openingHour, (time, index) => (
                                                <div key={index} className='outletDetailModal-modal-body-inner-content-time-container'>
                                                    <div className='outletDetailModal-modal-body-inner-content-time-container-day'>
                                                        <div>
                                                            {t(`${time?.label}`)}
                                                        </div>
                                                    </div>
                                                    <div className='outletDetailModal-modal-body-inner-content-time-container-hour'>
                                                        <div>
                                                            {t(`${time?.value}`)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalComponent>
        </>
    );
};

export default OutletDetailModal;
