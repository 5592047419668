import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import searchGray from '../../assets/icons/searchGray.svg';
import deleteIconSvg from '../../assets/icons/delete.svg';

interface InputBoxProps {
    inputValue: string;
    searchIcon?: boolean;
    locationIcon?: boolean;
    deleteIcon?: boolean;
    text?: string,
    background?: string,
    setInputValue: (value: string) => void;
}

const InputBox: React.FC<InputBoxProps> = ({
    inputValue,
    setInputValue,
    searchIcon,
    locationIcon,
    deleteIcon,
    text,
    background
}) => {
    const { t } = useTranslation();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(event.target.value);
    };

    const removeText = () => {
        setInputValue('');
    };


    return (
        <div className='inputBox' style={{ backgroundColor: background }}>
            {searchIcon && (
                <div className='inputBox-searchLogo'>
                    <img src={searchGray} />
                </div>
            )}
            {locationIcon && (
                <div className='inputBox-makerLogo'>
                    <img src={require('../../assets/images/marker.png')} />
                </div>
            )}
            <input style={{ backgroundColor: background }} type="text" value={inputValue} onChange={handleChange} placeholder={text ? t(`${text}`) : t("Search stalls or cuisines")} />
            {deleteIcon && (
                <div className='inputBox-deleteLogo' onClick={() => removeText()}>
                    <img src={deleteIconSvg} />
                </div>
            )}
        </div>
    );
};

export default InputBox;
