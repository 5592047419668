import React, { useState, useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import QuantityButton from '../QuantityButton';
import { Option } from '../../types/dataType';

interface ProductDetailQuantityButtonProps {
    item: Option;
    quantity: number;
    setAction: (quantity: number) => void;
}


const ProductDetailQuantityButton: React.FC<ProductDetailQuantityButtonProps> = ({
    item, quantity, setAction
}) => {
    const [qty, setQty] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        setQty(quantity);
    }, [quantity]);

    const increaseQty = () => {
        const newQty = qty + 1;
        setQty(newQty);
        setAction(newQty);
    };

    const decreaseQty = () => {
        if (qty > 0) {
            const newQty = qty - 1;
            setQty(newQty);
            setAction(newQty);
        }
    };

    return (
        <div className='ProductDetailQuantityButton-inner'>
            <div className='ProductDetailQuantityButton-inner-label'>
                {t(`${item?.name}`)}
            </div>
            <div className='ProductDetailQuantityButton-inner-value'>
                {item?.price === 0 ? t('Free') : `+ ${item?.price.toFixed(2)}`}
            </div>
            <div className='ProductDetailQuantityButton-inner-button'>
                <QuantityButton quantity={qty} increaseQuantity={increaseQty} decreaseQuantity={decreaseQty} />
            </div>
        </div>
    );
};

export default ProductDetailQuantityButton;
