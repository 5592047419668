import React, { useState, useEffect } from 'react';
import './index.scss';
import plus from '../../assets/icons/plus.svg';
import minus from '../../assets/icons/minus.svg';

interface CartQuantityButtonProps {
    quantity?: number;
    increaseQuantity: () => void;
    decreaseQuantity: () => void
}


const CartQuantityButton: React.FC<CartQuantityButtonProps> = ({
    quantity, increaseQuantity, decreaseQuantity
}) => {

    const [quantityAmount, setQuantityAmount] = useState<number>(0);


    useEffect(() => {
        const number = quantity
        if (number || number === 0) {
            setQuantityAmount(number)
        }
    }, [quantity]);

    return (
        <div className='cartQuantityButton'>
            <div className='cartQuantityButton-inner'>
                <div className='cartQuantityButton-inner-icon' onClick={() => increaseQuantity()}>
                    <img src={plus} />
                </div>
                <div className='cartQuantityButton-inner-quantity'>
                    {quantityAmount}
                </div>
                <div className='cartQuantityButton-inner-icon' onClick={() => decreaseQuantity()}>
                    <img src={minus} />
                </div>
            </div>
        </div>
    );
};

export default CartQuantityButton;
