import React, { useEffect, useState } from 'react';
import './index.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import * as _ from 'lodash';

const QR = () => {
  const { qrCode } = useParams();
  const { getQRDetails, outletData, error } = useStore()
  const navigate = useNavigate();

  useEffect(() => {
    if (qrCode) {
      localStorage.setItem('qr', qrCode);
      localStorage.setItem('qr-exp-date', "null");
      localStorage.removeItem('cart');
      getQRDetails(qrCode, "null")
    }
  }, [qrCode]);

  useEffect(() => {
    if (!_.isEmpty(outletData)) {
      navigate('/outlet')
    }
  }, [outletData]);


  useEffect(() => {
    console.log('error', error)
    if (error) {
      navigate('/')
      alert('Invalid QR');
    }
  }, [error]);

  return null;
};

export default QR;
