import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import './index.scss';


interface ModalComponentProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    children: React.ReactNode;
    fullHeight?: boolean
}

// Functional component with props type
const ModalComponent: React.FC<ModalComponentProps> = ({
    modal,
    setModal,
    children,
    fullHeight
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        setOpenModal(modal)
        if (modal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [modal]);

    const closeModal = () => {
        if (!fullHeight) {
            setModal(false);
            setOpenModal(false)
            document.body.style.overflow = '';
        }
    };

    return (
        <div className={`modalContainer ${openModal ? 'active' : ''}`} onClick={(e) => {
            if (e.target === e.currentTarget) {
                closeModal();
            }
        }}>
            <div className={`modalContainer-container ${fullHeight ? 'full' : ''}`}>
                {children}
            </div>
        </div>
    )

};

export default ModalComponent;
