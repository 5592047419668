import React, { useState, useEffect } from 'react';
import './index.scss';
import plusWhite from '../../assets/icons/plusWhite.svg';
import plus from '../../assets/icons/plus.svg';
import minus from '../../assets/icons/minus.svg';

interface QuantityButtonProps {
    quantity?: number;
    borderColor?: string;
    increaseQuantity: () => void;
    decreaseQuantity: () => void
}


const QuantityButton: React.FC<QuantityButtonProps> = ({
    quantity, increaseQuantity, decreaseQuantity, borderColor
}) => {

    const [quantityAmount, setQuantityAmount] = useState<number>(0);


    useEffect(() => {
        const number = quantity
        if (number || number === 0) {
            setQuantityAmount(number)
        }
    }, [quantity]);

    return (
        <div className='quantityButton' >
            {quantityAmount === 0 ? (
                <div className='quantityButton-inner default' onClick={() => increaseQuantity()}>
                    <div className='quantityButton-inner-icon'>
                        <img src={plusWhite} />
                    </div>
                </div>
            ) : (
                <div className='quantityButton-inner' style={{ borderColor: borderColor }}>
                    <div className='quantityButton-inner-icon' onClick={() => increaseQuantity()}>
                        <img src={plus} />
                    </div>
                    <div className='quantityButton-inner-quantity'>
                        {quantityAmount}
                    </div>
                    <div className='quantityButton-inner-icon' onClick={() => decreaseQuantity()}>
                        <img src={minus} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuantityButton;
