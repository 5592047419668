import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import InputBox from '../InputBox';
import ItemContainer from '../../components/ItemContainer';
import { Store } from '../../types/dataType';
import ModalComponent from '../ModalComponent';
import blackClose from '../../assets/icons/blackClose.svg';

interface SearchStoreModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    data?: Store[] | null;
}


const SearchStoreModal: React.FC<SearchStoreModalProps> = ({
    modal,
    setModal,
    data,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<Store[] | null>(null);
    const [allItems, setAllItems] = useState<Store[] | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();



    useEffect(() => {
        if (data) {
            setFilteredItems(data);
            setAllItems(data);
        }
    }, [data]);


    const closeModal = () => {
        setModal(false);
    };



    const handleSearchChange = (searchValue: string) => {
        setInputValue(searchValue.toLowerCase());
        if (allItems) {
            const filtered = allItems.filter(item => {
                try {
                    const nameMatch = item?.display_name?.toLowerCase().includes(searchValue.toLowerCase());

                    const typeMatch = item.type?.some(type => type.toLowerCase().includes(searchValue.toLowerCase()));

                    const tagMatch = item.tag?.some(tag => tag.toLowerCase().includes(searchValue.toLowerCase()));

                    return nameMatch || typeMatch || tagMatch;
                } catch (error) {
                    return false;
                }
            });
            setFilteredItems(filtered);
        }
    };

    return (
        <>
            <ModalComponent modal={modal} setModal={setModal} fullHeight >
                <div className='searchStoreModal-modal-header'>
                    <div className='searchStoreModal-modal-header-inner'>
                        <div className='searchStoreModal-modal-header-inner-icon' onClick={() => closeModal()}>
                            <img src={blackClose} />
                        </div>
                        <div className='searchStoreModal-modal-header-inner-text'>
                            {t(`Search`)}
                        </div>
                    </div>
                    <div className='searchStoreModal-modal-header-input'>
                        <InputBox deleteIcon searchIcon inputValue={inputValue}
                            setInputValue={handleSearchChange} />
                    </div>
                </div>
                <div className='searchStoreModal-modal-body'>
                    {!_.isEmpty(filteredItems) ? (
                        <div className='searchStoreModal-modal-body-result'>
                            {_.map(filteredItems, (item, index) => (
                                <div key={index} className='searchStoreModal-modal-body-result-item' onClick={() => {
                                    setModal(false)
                                    navigate(`/product/${item._id}`)
                                }}>
                                    <ItemContainer item={item} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='searchStoreModal-modal-body-noResult'>
                            <div className='searchStoreModal-modal-body-noResult-notFound'>
                                <img src={require('../../assets/images/notFound.png')} />
                                <div className='searchStoreModal-modal-body-noResult-notFound-title'>
                                    {t('We didn’t find a match')}
                                </div>
                                <div className='searchStoreModal-modal-body-noResult-notFound-desc'>
                                    {t('Try searching for something else instead.')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ModalComponent>
        </>
    );
};

export default SearchStoreModal;
