import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { CartItem, Item, Address, Store, OptionGroup, PaymentMethod, Option } from '../types/dataType';
import * as _ from 'lodash';
import { fullBrowserVersion, browserName, engineName, mobileVendor, deviceType, osName } from "react-device-detect";
import { CREATE_PRE_ORDER } from '../query/placeOrder'
import { useApolloClient, ApolloClient } from '@apollo/client';
import { useLoading } from './LoadingContent';
import { useNavigate } from 'react-router-dom';

interface CartContextProps {
    cart: CartItem[];
    setToCart: (
        item: Item,
        store: Store | undefined,
        addOn: OptionGroup[],
        quantity: number
    ) => void;
    EditToCart: (
        id: number,
        store: Store | undefined,
        addOn: OptionGroup[],
        quantity: number
    ) => void;
    totalAmount: number;
    method: string | undefined;
    serviceTax: number;
    ssl: number;
    payAmount: number;
    selectMethod: (
        method?: string,
    ) => void;
    selectTable: (
        tableNumber?: string,
    ) => void;
    tableNumber: string | undefined;
    pickAddress: (
        address: Address
    ) => void;
    selectedAddress?: Address;
    setSelectedPaymentGateway: (
        paymentMethod: PaymentMethod,
    ) => void;
    selectedPaymentGateway?: PaymentMethod
    placeOrder: () => Promise<void>;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [cart, setCart] = useState<CartItem[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [serviceTax, setServiceTax] = useState<number>(0);
    const [ssl, setSSL] = useState<number>(0);
    const [payAmount, setPayAmount] = useState<number>(0);
    const [method, setMethod] = useState<string | undefined>(undefined);
    const [tableNumber, setTableNumber] = useState<string | undefined>(undefined);
    const [nextId, setNextId] = useState<number>(1);
    const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);
    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<PaymentMethod | undefined>(undefined);
    const navigate = useNavigate();
    const apolloClient: ApolloClient<any> = useApolloClient();

    useEffect(() => {
        const storageData = localStorage.getItem('cart')
        if (storageData) {
            const carts = JSON.parse(storageData)
            if (carts?.cart && carts?.cart.length !== 0) {
                setCart(carts?.cart)
                setNextId(carts?.cart?.length + 1)
            }
            if (carts?.method && carts?.method.length !== 0) {
                setMethod(carts?.method)
            }
            if (carts?.table) {
                setTableNumber(carts?.table)
            }
            if (carts?.deliveyAddress) {
                setSelectedAddress(carts?.deliveyAddress)
            }
            if (carts?.paymentMethod) {
                setSelectedPaymentGateway(carts?.paymentMethod)
            }
        }
    }, [])


    const setToCart = (
        item: Item,
        store: Store | undefined,
        option: OptionGroup[] | undefined,
        quantity: number
    ) => {
        const existingItemIndex = cart.findIndex(
            (cartItem) =>
                cartItem.item._id === item._id &&
                JSON.stringify(cartItem.item.option_group_list) === JSON.stringify(option)
        );

        const updatedAddOnWithBalance = calculateTotalAmounts(option ?? []);

        const totalSum = updatedAddOnWithBalance?.reduce((sum, group) => {
            return sum + (group.totalAmount ?? 0);
        }, 0);

        const totalAmount = ((item.price ?? 0) + (totalSum ?? 0)) * quantity;
        if (existingItemIndex !== -1) {
            const updatedCart = [...cart];
            const existingItem = updatedCart[existingItemIndex];
            existingItem.quantity += quantity;
            existingItem.totalAmount += totalAmount;

            if (existingItem.quantity <= 0) { updatedCart.splice(existingItemIndex, 1); }
            setCart(updatedCart);
        } else {
            if (quantity > 0) {
                const newItem = {
                    id: nextId,
                    item,
                    store,
                    addOn: updatedAddOnWithBalance || [],
                    quantity,
                    totalAmount,
                };
                setCart([...cart, newItem]);
                setNextId(nextId + 1);
            }
        }
    };

    const calculateTotalAmounts = (groups: OptionGroup[]) => {
        return groups.map(group => {

            const updatedOptions = group?.options?.map(option => {
                const qty = option?.qty ?? 0;
                const price = option?.price ?? 0;
                const totalAmount = qty * price;
                return {
                    ...option,
                    totalAmount: totalAmount
                };
            });


            const totalAmountForGroup = updatedOptions?.reduce((sum, option) => {
                return sum + option.totalAmount;
            }, 0);


            return {
                ...group,
                options: updatedOptions,
                totalAmount: totalAmountForGroup
            };
        });
    };


    const EditToCart = (
        id: number,
        store: Store | undefined,
        option: OptionGroup[] | undefined,
        quantity: number
    ) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.map((cartItem) => {
                if (cartItem.id === id) {
                    const updatedAddOnWithBalance = calculateTotalAmounts(option ?? []);
                    const totalSum = updatedAddOnWithBalance?.reduce((sum, group) => {
                        return sum + (group.totalAmount ?? 0);
                    }, 0);

                    const totalAmount = ((cartItem.item.price ?? 0) + (totalSum ?? 0)) * quantity;

                    return {
                        ...cartItem,
                        store,
                        addOn: updatedAddOnWithBalance,
                        quantity,
                        totalAmount,
                    };
                }
                return cartItem;
            });


            return updatedCart.filter((cartItem) => cartItem.quantity > 0);
        });
    };


    const getTotalAmount = () => {
        return cart.reduce((sum, item) => sum + item.totalAmount, 0);
    };


    const selectMethod = (method?: string) => {
        setMethod(method);
        if (method === 'delivery') {
            setTableNumber(undefined);
        }
    };

    const pickAddress = (address?: Address) => {
        setSelectedAddress(address);
    };

    const selectTable = (tableNumber?: string) => {
        setTableNumber(tableNumber);
    };

    useEffect(() => {
        const amount = getTotalAmount();
        const tax = (amount * 10) / 100;
        const ssl = (amount * 6) / 100;
        setTotalAmount(amount)
        setServiceTax(tax);
        setSSL(ssl);
        setPayAmount(amount + tax + ssl);
        const data = {
            method: method,
            cart: cart,
            table: tableNumber,
            sumAmount: amount,
            tax: tax,
            ssl: ssl,
            totalPayAmount: amount + tax + ssl,
            deliveyAddress: selectedAddress,
            paymentMethod: selectedPaymentGateway
        }
        console.log('cart', data)
        localStorage.setItem('cart', JSON.stringify(data));
    }, [cart, method, tableNumber, selectedAddress, selectedPaymentGateway]);

    const simplayCartItem = async (cartItem: CartItem[]) => {
        return Promise.all(cartItem.map(async item => ({
            id: item.id.toString(),
            product_id: item?.item?._id,
            store_id: item?.store?._id,
            totalPrice: item?.totalAmount.toString(),
            quantity: item?.quantity,
            options: await simplayOptionGroup(item?.addOn ?? []), // Await the result
        })));
    };

    const simplayOptionGroup = (optionGroups: OptionGroup[]) => {
        return Promise.all(optionGroups.map(async item => ({
            group_id: item?._id?.toString(),
            selected: simplayOption(item?.options ?? []), // Handle undefined options
            price: item?.totalAmount?.toString(),
        })));
    };

    const simplayOption = (options: Option[]) => {
        return options.map(item => ({
            name: item?.name,
            // qty: item?.qty,
            price: item?.totalAmount,
        }));
    };



    const placeOrder = async () => {
        try {
            const storageData = localStorage.getItem('cart')
            const qr = localStorage.getItem('qr')
            if (storageData && qr) {
                const carts = JSON.parse(storageData)
                const newData = {
                    table_no: carts?.table ? carts?.table : null,
                    cart: await simplayCartItem(carts.cart),
                    order_type: carts.method,
                    payment_type: carts?.carts?.method?.value, // H5-Spay payment_type value 
                    qr_code: qr,
                    device: `type: ${deviceType} - ${browserName} - ${fullBrowserVersion} - ${engineName} , Mobile: ${mobileVendor}`,
                    firebase_user_id: null
                }
                console.log('newData', newData)
                let result = await apolloClient.mutate({
                    mutation: CREATE_PRE_ORDER,
                    variables: { input: newData },
                })
                console.log('result', result)
                await localStorage.removeItem('cart');
                if (result) {
                    alert('Send Order Successful');
                }

                // if (result.status && result.status !== 'error') {
                //     // setCart([])
                //     // ls.remove('cart')
                //     // setSavingOrder(false)
                //     return result
                // }
                // // // setCart([])
                // // // ls.remove('cart')
                // // setSavingOrder(false)
                // return result
            } else {
                navigate('/')
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    return (
        <CartContext.Provider value={{ cart, totalAmount, setToCart, EditToCart, serviceTax, ssl, payAmount, method, selectMethod, selectTable, tableNumber, pickAddress, setSelectedPaymentGateway, selectedPaymentGateway, placeOrder, selectedAddress }}>
            {children}
        </CartContext.Provider>
    );
};
