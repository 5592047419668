import { gql } from '@apollo/client';

export const CREATE_PRE_ORDER = gql`
  mutation CreatePreOrder($input: CreatePreOrderInput!) {
    createPreOrder(input: $input) {
      result {
        _id
        table_no
        order_type_label
        order_type
        payment_type
        save_user_profile
        qr_code
        qr_code_name
        device
        firebase_user_id
        isPreOrderComplated
        payment_ref_no
        created_date
        cart {
          product_id
          name
          price
          quantity
          netPrice
          totalPrice
          id
          store_id
          store_name
          options {
            group_id
            group_name
            price
            optional
            min
            max
            selected {
              name
              price
            }
          }
        }
      }
    }
  }
`;