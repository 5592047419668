import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_ID = gql`
 query Product($id: String!) {
  product(input: { _id: $id }) {
    result {
      _id
      store_id
      name
      sku_name
      sku_number
      description
      main_image
      price
      brand
      qty
      auto_deduct_qty
      sort
      status
      tags
      categories
      options
      created_date
      updated_date
      option_group_list {
        _id
        store_id
        group_name
        description
        max_select
        min_select
        sort
        options {
            name
            description
            image
            price
            qty
            deduct_qty
            sort
            status
        }
      }
      images {
        url
      }
      category_list {
        _id
        name
        display_name
        sort
        store_id
        status
      }
    }
  }
}
`;