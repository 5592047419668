import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Store, Item, CartItem, OptionGroup } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import ModalComponent from '../ModalComponent';


interface SelectedProductDetailModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    data?: Item;
}

const SelectedProductDetailModal: React.FC<SelectedProductDetailModalProps> = ({
    modal,
    setModal,
    data,
}) => {
    const [dataItems, setDataItems] = useState<Item>();
    const [cartItems, setCartItems] = useState<CartItem[]>([]);
    const { t } = useTranslation();
    const { cart } = useCart();
    const navigate = useNavigate();



    useEffect(() => {
        if (data) {
            setDataItems(data);
            const sameItems = cart
                .filter(cartItem => cartItem.item._id === data?._id)
                .map(cartItem => cartItem);
            setCartItems(sameItems);
        }
    }, [data, cart]);

    const subItemDisplay = (optionGroup: OptionGroup) => {
        return (
            <div>
                {optionGroup?.options
                    ?.filter(item => (item?.qty ?? 0) > 0)
                    .map((item, index) => (
                        <div key={index}>{item?.name} X {item?.qty}</div>
                    ))}
            </div>
        );
    };

    const editItemOrder = (id: number) => {
        setModal(false)
        document.body.style.overflow = '';
        navigate(`/editOrderItemPage/${data?._id}/${id}`)
    }

    return (
        <>
            <ModalComponent modal={modal} setModal={setModal} >
                <div className='selectedProductDetailContainer'>
                    <div className='selectedProductDetailContainer-header'>
                        <div className='selectedProductDetailContainer-header-label'>
                            {t(`${dataItems?.name}`)}
                        </div>
                        <div className='selectedProductDetailContainer-header-price'>
                            <div className='selectedProductDetailContainer-header-price-value'>
                                {(dataItems?.price ?? 0).toFixed(2)}
                            </div>
                            <div className='selectedProductDetailContainer-header-price-label'>
                                {t('Base price')}
                            </div>
                        </div>
                    </div>
                    <div className='selectedProductDetailContainer-body'>
                        {_.map(cartItems, (order, index) => (
                            <div key={index} className='selectedProductDetailContainer-body-item' onClick={() =>
                                editItemOrder(order?.id)}>
                                <div className='selectedProductDetailContainer-body-item-img'>
                                    <img src={order?.item?.main_image || require('../../assets/images/logo.png')} />
                                </div>
                                <div className='selectedProductDetailContainer-body-item-detail'>
                                    <div className='selectedProductDetailContainer-body-item-detail-text'>
                                        {t(`${order?.item?.name}`)}
                                    </div>
                                    {_.map(order?.addOn, (item, index) => (
                                        <div key={index} className='selectedProductDetailContainer-body-item-detail-text'>
                                            {subItemDisplay(item)}
                                        </div>
                                    ))}
                                </div>
                                <div className='selectedProductDetailContainer-body-item-qty'>
                                    <div className='selectedProductDetailContainer-body-item-qty-text'>
                                        {order?.quantity}
                                    </div>
                                </div>
                                <div className='selectedProductDetailContainer-body-item-price'>
                                    <div className='selectedProductDetailContainer-body-item-price-text'>
                                        {order?.totalAmount.toFixed(2)} (incl.tax)
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalComponent>
        </>
    )

};

export default SelectedProductDetailModal;
