import React, { useEffect, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import checkBoxActive from '../../assets/icons/checkBoxActive.svg';
import checkBoxUnactive from '../../assets/icons/checkBoxUnactive.svg';


interface CheckBoxProps {
  imageIcon?: string;
  text?: string;
  active: boolean
  // action: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  imageIcon,
  text,
  active
  // action,
}) => {

  const [status, setStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setStatus(active);
  }, [active]);


  return (
    <div className={`checkBoxContainer ${status ? 'active' : ''}`} >
      <div className='checkBoxContainer-icon'>
        <img src={imageIcon} />
      </div>
      <div className='checkBoxContainer-title'>
        {t(`${text}`)}
      </div>
      <div className='checkBoxContainer-checkIcon'>
        {status ? <img style={{ width: 20, height: 20 }} src={checkBoxActive} /> :
          <img style={{ width: 20, height: 20 }} src={checkBoxUnactive} />}
      </div>
    </div>
  );
};

export default CheckBox;