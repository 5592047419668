import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../contexts/StoreContent';
import { useCart } from '../../contexts/CartContent';

const OutletHeaderContainer: React.FC = () => {
  const { t } = useTranslation();
  const { outletData } = useStore();
  const { method, tableNumber } = useCart();

  if (outletData) {
    return (
      <div className='outletHeaderContainer'>
        <div className='outletHeaderContainer-left'>
          <div className='outletHeaderContainer-left-logo'>
            <img src={outletData?.company?.main_image || require('../../assets/images/logo.png')} />
          </div>
        </div>
        <div className='outletHeaderContainer-right'>
          <div className='outletHeaderContainer-right-label'>
            {outletData?.company?.display_name ? t(`${outletData?.company?.display_name}`) : ''}
          </div>
          <div className='outletHeaderContainer-right-method'>
            <div className='outletHeaderContainer-right-method-label'>
              {t(`${method}`)}
            </div>
            {(tableNumber && (method !== 'delivery')) && (
              <div className='outletHeaderContainer-right-method-table'>
                {t(`${tableNumber}`)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default OutletHeaderContainer;