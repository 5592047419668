import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { GET_QR } from '../query/qr_codes'
import { GET_STORE_BY_ID } from '../query/stores'
import { GET_PRODUCT_BY_ID } from '../query/products'
import { Outlet, Store, Item } from '../types/dataType';
import { useLoading } from './LoadingContent';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as _ from 'lodash';
import config from '../lib/config'


dayjs.extend(utc);
dayjs.extend(timezone);

const MALAYSIA_TIMEZONE = 'Asia/Kuala_Lumpur';

interface StoreContextProps {
    getQRDetails: (qrCode: string | null, qrExpire: string | null) => Promise<void>;
    getStoreDetails: (storeId: string | null) => Promise<Store | undefined>;
    getProductDetails: (productId: string | null) => Promise<Item | undefined>;
    outletData: Outlet;
    storeData: Store;
    productData: Item;
    error?: Boolean;
}

const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}


export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [outletData, setOutletData] = useState({})
    const [storeData, setStoreData] = useState({})
    const [productData, setProductData] = useState({})
    const apolloClient: ApolloClient<any> = useApolloClient();
    const { showLoading, hideLoading } = useLoading() || {};
    const [error, setError] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        let qrCode = await localStorage.getItem('qr');
        let qrExpire = await localStorage.getItem('qr-exp-date');
        if (qrCode && qrExpire) {
            if (_.isEmpty(outletData)) {
                getQRDetails(qrCode, qrExpire)
            }
        } else {
            await localStorage.removeItem('cart');
            navigate('/')
        }
    }

    const isQRExpired = async () => {
        const qrExpire = await localStorage.getItem('qr-exp-date');

        if (qrExpire === "null") { return }

        if (dayjs().isAfter(dayjs(qrExpire))) {
            await localStorage.removeItem('cart');
            await localStorage.removeItem('qr');
            navigate('/')
        }
    }

    const getQRDetails = async (qrCode: string | null, qrExpire: string | null) => {
        await isQRExpired();
        if (qrCode) {
            showLoading?.()
            try {
                console.log('qrCode', qrCode);
                const result = await apolloClient.query({
                    query: GET_QR,
                    variables: { qr_code: qrCode },
                });
                const qrData = result.data?.qrCode?.result;
                if (qrExpire === "null") {
                    await localStorage.setItem('qr-exp-date', dayjs().add(config?.EXPIRE_TIME, 'minute').tz(MALAYSIA_TIMEZONE)
                        .format('YYYY-MM-DD HH:mm:ss').toString());
                }
                // console.log('qrData', qrData);
                if (qrData === null) {
                    setError(true)
                }
                setOutletData(qrData);
                hideLoading?.();
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
    };

    const getStoreDetails = async (storeId: string | null): Promise<Store | undefined> => {
        if (storeId) {
            showLoading?.()
            // console.log('storeId', storeId)
            try {
                const result = await apolloClient.query({
                    query: GET_STORE_BY_ID,
                    variables: { id: storeId },
                });
                // console.log('result', result);
                const store = result.data?.store?.result;
                setStoreData(store);
                // console.log('store', store);
                hideLoading?.();
                return store;
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
        return undefined;
    };

    const getProductDetails = async (productId: string | null): Promise<Item | undefined> => {
        // console.log('productId', productId)
        if (productId) {
            showLoading?.()
            try {
                const result = await apolloClient.query({
                    query: GET_PRODUCT_BY_ID,
                    variables: { id: productId },
                });
                // console.log('result', result);
                const product = result.data?.product?.result;
                // console.log('store', product);
                setProductData(product);
                hideLoading?.();
                return product;
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
        return undefined;
    };



    return (
        <StoreContext.Provider value={{ getQRDetails, outletData, getStoreDetails, storeData, getProductDetails, productData, error }}>
            {children}
        </StoreContext.Provider>
    );
};
