import React, { useEffect, useState } from 'react';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OutletHeaderContainer from '../../components/OutletHeaderContainer';
import { PaymentMethod, CartItem } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import { useStore } from '../../contexts/StoreContent';
import blackClose from '../../assets/icons/blackClose.svg';


export interface Status {
  label: string;
  description: string;
}


const OrderPage = () => {
  const [cartData, setCartData] = useState<CartItem[]>([]);
  const { t } = useTranslation();
  const { cart, totalAmount, ssl, serviceTax, payAmount, method } = useCart();
  const { outletData } = useStore();
  const [selectedStatus, setSelectedStatus] = useState<Status>();
  const navigate = useNavigate();

  useEffect(() => {
    if (cart) {
      setCartData(cart)
    }
  }, [cart]);

  const status = [{
    label: 'Confirmed',
    description: 'We got your order'
  }, {
    label: 'Processing',
    description: 'Processing your order'
  }, {
    label: 'Ready',
    description: 'Your meal is ready'
  }, {
    label: 'Completed',
    description: 'Enjoy your meal!'
  }]

  // useEffect(() => {
  //   if (status) {
  //     setSelectedStatus(status[0])
  //   }
  // }, [status]);

  return (
    <div className='orderContainer'>
      <div className='orderContainer-header'>
        <div className='orderContainer-header-close'>
          <div className='orderContainer-header-close-icon' onClick={() => navigate(`/store`)}>
            <img src={blackClose} />
          </div>
        </div>
      </div>
      <div className='orderContainer-body'>
        <div className='orderContainer-body-orderNumberSection'>
          <div className='orderContainer-body-orderNumberSection-label'>
            {t(selectedStatus?.description || '')}
          </div>
          <div className='orderContainer-body-orderNumberSection-number'>
            #1669451277
          </div>
        </div>
        <div className='orderContainer-body-statusSection'>
          <div className='orderContainer-body-statusSection-inner'>
            {_.map(status, (item, index) => (
              <div key={index} className='orderContainer-body-statusSection-inner-item'>
                <div className='orderContainer-body-statusSection-inner-item-label'>
                  {t(`${item?.label}`)}
                </div>
                <div className={`orderContainer-body-statusSection-inner-item-status ${selectedStatus?.label === item?.label ? 'active' : ''}`}>
                </div>
              </div>
            ))}
          </div>
        </div>
        <OutletHeaderContainer />
      </div>
    </div>
  );
};

export default OrderPage;
