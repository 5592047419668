import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

// Define the props interface
interface LinkPathProps {
  color?: string;
  text: string;
  action: () => void;
  fontSize?: number;
  fontWeight?: string | number;
}

// Functional component with props type
const LinkPath: React.FC<LinkPathProps> = ({
  color = '#190DA2',
  text,
  action,
  fontSize = 16,
  fontWeight = '400'
}) => {
  const { t } = useTranslation();
  return (
    <a  
    onClick={() =>action()}  
    style={{
        textAlign: 'center',
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight
      }}>
        {t(`${text}`)}
    </a>
  );
};

export default LinkPath;