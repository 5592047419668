import React, { useEffect, useState } from 'react';
import { Scanner, IScannerClassNames, IScannerComponents } from '@yudiel/react-qr-scanner';
import { Modal } from 'react-bootstrap';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import closeIcon from '../../assets/icons/close.svg'
import './index.scss';

interface QrScannerModalProps {
    openCamera: boolean;
    setOpenCamera: (value: boolean) => void;
}

interface QRData {
    rawValue: string;
}

const classNames: IScannerClassNames = {
    container: 'qr-scanner-container',
    video: 'qr-scanner-video',
};


const components: IScannerComponents = {
    finder: false
};

// Functional component with props type
const QrScannerModal: React.FC<QrScannerModalProps> = ({
    openCamera = false,
    setOpenCamera,
}) => {
    const [scanned, setScanned] = useState<boolean>(true);
    const navigate = useNavigate();
    const { getQRDetails, outletData } = useStore()

    const receiveData = (data: QRData) => {
        setScanned(false);
        setOpenCamera(false);
        const qr = data.rawValue;
        if (qr) {
            localStorage.setItem('qr', qr);
            localStorage.setItem('qr-exp-date', "null");
            localStorage.removeItem('cart');
            getQRDetails(qr, "null")
        }
    }

    useEffect(() => {
        // console.log('outletData', outletData)
        if (!_.isEmpty(outletData)) {
            navigate('/outlet')
        }
    }, [outletData]);


    useEffect(() => {
        setScanned(openCamera);
        // console.log('openCamera', openCamera)
    }, [openCamera]);

    const handleClose = () => {
        setOpenCamera(false)
        setScanned(false)
    };

    return (
        <>
            <Modal show={scanned} onHide={handleClose} dialogClassName="modal-fullscreen"
                aria-labelledby="exampleModalLabel">
                <div className='qr-scanner'>
                    <div className='qr-scanner-wrapper'>
                        <Scanner onScan={(result) => {
                            receiveData(result[0])
                        }}
                            paused={!scanned}
                            allowMultiple={false}
                            classNames={classNames}
                            components={components}
                        >
                            <div className='qr-scanner-closeBtn' onClick={() => handleClose()}>
                                <img src={closeIcon} />
                                <div>Scan QR Code</div>
                            </div>
                            <div className='qr-scanner-icon-contianer'>
                                <img src={require('../../assets/images/scanner.png')} />
                            </div>
                        </Scanner>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default QrScannerModal;
