import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import ProductDetailRadioButton from '../../components/ProductDetailRadioButton';
import { Item, SizeOption, OptionGroup, Option } from '../../types/dataType';
import ProductDetailQuantityButton from '../../components/ProductDetailQuantityButton';
import closeWhite from '../../assets/icons/closeWhite.svg';
import { useCart } from '../../contexts/CartContent';
import { useStore } from '../../contexts/StoreContent';
import Button from '../../components/Button';
import CartQuantityButton from '../../components/CartQuantityButton';


const EditOrderItemPage = () => {
  const { productId, cartItemId } = useParams();
  const [dataItems, setDataItems] = useState<Item>();
  const [settingData, setSettingData] = useState<OptionGroup[]>([]);
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();
  const { getProductDetails, storeData } = useStore();
  const { cart, EditToCart } = useCart();
  const navigate = useNavigate();


  useEffect(() => {
    if (productId) {
      getProductDetails(productId).then((selecteProductData) => {
        if (selecteProductData) {
          const selectedItem = cart.find(cartItem => cartItem.id === Number(cartItemId));
          if (selectedItem) {

            setSettingData(selectedItem?.addOn || []);
            setQuantity(selectedItem?.quantity)
          } else {
            setSettingData(selecteProductData?.option_group_list || []);
          }
          setDataItems(selecteProductData);
        }
      }).catch((error) => {
        console.error('Error fetching product details:', error);
      });
    }
  }, [productId, cartItemId, cart]);


  const handleOptional = (groupIndex: number, index: number) => {

    const updatedOptionGroups = [...settingData];

    if (updatedOptionGroups[groupIndex] && updatedOptionGroups[groupIndex].options) {
      const updatedOptions = [...updatedOptionGroups[groupIndex].options];
      const min = parseInt(updatedOptionGroups[groupIndex]?.min_select ?? '0', 10);
      const max = parseInt(updatedOptionGroups[groupIndex]?.max_select ?? '0', 10);

      if (updatedOptions[index].qty == 1) {
        if (min === 0) {
          updatedOptions[index].qty = 0;
          // updatedOptions[index].totalAmount = 0;
        }
      } else {
        if (min === 0 && max === 1) {
          _.forEach(updatedOptions, (item) => {
            item.qty = 0;
          });
        }
        updatedOptions[index].qty = 1;
        // updatedOptions[index].totalAmount = 1 * (updatedOptions[index].price ?? 0);
      }

      // const groupTotalAmount = updatedOptions.reduce((total, option) => {
      //     return total + (option.totalAmount ?? 0);
      // }, 0);

      updatedOptionGroups[groupIndex] = {
        ...updatedOptionGroups[groupIndex],
        options: updatedOptions,
        // totalAmount: groupTotalAmount,
      };
      // console.log('handleOptional', updatedOptionGroups)
      setSettingData(updatedOptionGroups);
    }
  };

  const handleQtyChange = (qty: number, groupIndex: number, index: number) => {

    const updatedOptionGroups = [...settingData];

    if (updatedOptionGroups[groupIndex] && updatedOptionGroups[groupIndex].options) {
      const updatedOptions = [...updatedOptionGroups[groupIndex].options];

      updatedOptions[index].qty = qty;
      // updatedOptions[index].totalAmount = qty * (updatedOptions[index].price ?? 0);

      // const groupTotalAmount = updatedOptions.reduce((total, option) => {
      //     return total + (option.totalAmount ?? 0);
      // }, 0);

      updatedOptionGroups[groupIndex] = {
        ...updatedOptionGroups[groupIndex],
        options: updatedOptions,
        // totalAmount: groupTotalAmount,
      };
      // console.log('handleQtyChange', updatedOptionGroups)
      setSettingData(updatedOptionGroups);

    }
  };


  const increaseQty = () => {
    const newQty = quantity + 1;
    setQuantity(newQty);
  };

  const decreaseQty = () => {
    if (quantity >= 0) {
      const newQty = quantity - 1;
      setQuantity(newQty);
    }
  };

  const updateCart = () => {
    if (dataItems) {
      console.log('settingData', settingData)
      EditToCart(Number(cartItemId), storeData, settingData, quantity);
      setQuantity(1);
      navigate(-1);
      setSettingData(dataItems?.option_group_list || []);
    }
  }

  const optionSettingDisplay = (option: OptionGroup, groupIndex: number) => {
    // console.log(option);
    const min = parseInt(option?.min_select ?? '0', 10);
    const max = parseInt(option?.max_select ?? '0', 10);
    if (max === 1) {
      return (
        <div className='editOrderItem-setting-inner'>
          <div className='editOrderItem-setting-inner-label'>
            {t(option?.group_name ?? '')}
          </div>
          <div className='editOrderItem-setting-inner-description'>
            {min === 0 ? t('Optional') : t('Pick 1')}
          </div>
          <div className='editOrderItem-setting-inner-option'>
            {_.map(option?.options, (item, index) => (
              <div className='editOrderItem-setting-inner-option-box'
                key={index}>
                <ProductDetailRadioButton item={item}
                  active={item?.qty === 1}
                  setSelectedAction={() => handleOptional(groupIndex, index)} />
              </div>
            ))}
          </div>
        </div>
      )
    } else if (max > 1) {
      return (
        <div className='editOrderItem-setting-inner top'>
          <div className='editOrderItem-setting-inner-label'>
            {t(option?.group_name ?? '')}
          </div>
          <div className='editOrderItem-setting-inner-description'>
            {min === 0 ? t('Optional') : t('Pick 1')}
          </div>
          <div className='editOrderItem-setting-inner-option'>
            {_.map(option?.options, (item, index) => (
              <div className='editOrderItem-setting-inner-option-box'
                key={index}>
                <ProductDetailQuantityButton
                  item={item}
                  quantity={item.qty ?? 0}
                  setAction={(qty) => handleQtyChange(qty, groupIndex, index)} />
              </div>
            ))}
          </div>
        </div>
      )
    }
  }


  return (
    <div className='editOrderItem'>
      <div className='editOrderItem-header'>
        <div className='editOrderItem-header-inner'>
          <div className='editOrderItem-header-inner-icon' onClick={() => navigate(-1)}>
            <img src={closeWhite} />
          </div>
        </div>
      </div>
      <div className='editOrderItem-img'>
        <img src={dataItems?.main_image} />
      </div>
      <div className='editOrderItem-detail'>
        <div className='editOrderItem-detail-inner'>
          <div className='editOrderItem-detail-inner-title'>
            {t(`${dataItems?.name}`)}
          </div>
          <div className='editOrderItem-detail-inner-price'>
            RM {(dataItems?.price ?? 0).toFixed(2)}
          </div>
          <div className='editOrderItem-detail-inner-description'>
            {dataItems?.category_list && dataItems.category_list.length > 0
              && dataItems.category_list
                .map(cat => cat.display_name ? t(`${cat.display_name}`) : '')
                .filter(Boolean)
                .join(' / ')
            }
          </div>
        </div>
      </div>
      <div className='editOrderItem-setting'>
        {_.map(settingData, (option, index) => (
          <div key={index}>
            {optionSettingDisplay(option, index)}
          </div>
        ))}
      </div>
      <div className='editOrderItem-addCartContainer'>
        <div className='editOrderItem-addCartContainer-qtySetting'>
          <CartQuantityButton quantity={quantity} increaseQuantity={increaseQty}
            decreaseQuantity={decreaseQty} />
        </div>
        <div className='editOrderItem-addCartContainer-btn'>
          <Button text={`${quantity > 0 ? "Update Cart" : "Back To Menu"}`} action={() => {
            updateCart()
          }} />
        </div>
      </div>
    </div>

  )
}

export default EditOrderItemPage;
