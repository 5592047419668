import { gql } from '@apollo/client';

export const GET_QR = gql`
  query QrCode($qr_code: String) {
    qrCode(input: { qr_code: $qr_code }) {
      result {
        _id
        qr_code
        name
        company_id
        type
        payment_types {
          label
          value
        }
        company {
          _id
          display_name
          address
          phone
          email
          description
          store_ids
          status
          company_code
          company_qr
          show_coordinates
          main_image
          store_list {
            _id
            display_name
            store_name
            company_id
            email
            tel
            website_url
            description
            logo_image
            status
            currency_id
            name
            store_company_reg_no
            last_bill_number
            isOpen
            updated_date
            whatsapp
            xp_printer_sn
            bank_acc_no
            bank_id
            order_status {
              label
              value
              next
            }
            images {
              images_uri
              type
            }
            store_operation_hour_list {
              day
              open_time
              close_time
            }
          }
        }
      }
    }
  }
`;