import React, { useEffect, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import checkBoxActive from '../../assets/icons/checkBoxActive.svg';
import checkBoxUnactive from '../../assets/icons/checkBoxUnactive.svg';

interface PaymentCheckBoxProps {
  imageIcon?: string;
  text: string;
  active: boolean;
  length?: number;
  index?: number;
}


const PaymentCheckBox: React.FC<PaymentCheckBoxProps> = ({
  imageIcon,
  text,
  active,
  length,
  index
}) => {

  const [status, setStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setStatus(active);
  }, [active]);


  return (
    <div className={`paymentCheckBoxContainer ${index === 0 ? 'first' : index === (length ?? 0) - 1 ? 'last' : ''
      }`} onClick={() => {
        setStatus(!status);
      }}>
      <div className='paymentCheckBoxContainer-icon'>
        <img src={imageIcon} />
      </div>
      <div className='paymentCheckBoxContainer-title'>
        {t(`${text}`)}
      </div>
      <div className='paymentCheckBoxContainer-checkIcon'>
        {status ?

          <img style={{ width: 20, height: 20 }} src={checkBoxActive} /> :
          <img style={{ width: 20, height: 20 }} src={checkBoxUnactive} />}
      </div>
    </div>
  );
};

export default PaymentCheckBox;