import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import QuantityButton from '../../components/QuantityButton';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { OptionGroup, CartItem, Store, Item } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import { useStore } from '../../contexts/StoreContent'
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/delete2.svg';

const CartPage = () => {
    const [cartData, setCartData] = useState<CartItem[]>([]);
    const { t } = useTranslation();
    const { outletData } = useStore()
    const { cart, totalAmount, EditToCart, ssl, serviceTax, payAmount, method, tableNumber } = useCart();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('tableNumber', tableNumber)
        if (cart) {
            setCartData(cart)
        }
    }, [cart, tableNumber]);

    const subItemDisplay = (optionGroup: OptionGroup) => {
        return (
            <div>
                {optionGroup?.options
                    ?.filter(item => (item?.qty ?? 0) > 0)
                    .map((item, index) => (
                        <div className='cartContainer-cartDetail-item-inner-detail-inner-detail-sub' key={index}>{item?.name} X
                            {item?.qty}</div>
                    ))}
            </div>
        );
    };

    const increaseQuantity = (item: CartItem) => {
        const quantity = item?.quantity + 1;
        EditToCart(item?.id, item?.store, item?.addOn || [], quantity);
    }

    const decreaseQuantity = (item: CartItem) => {
        const quantity = item?.quantity - 1;
        EditToCart(item?.id, item?.store, item?.addOn || [], quantity);
    }

    const deleteItem = (item: CartItem) => {
        const quantity = 0;
        EditToCart(item?.id, item?.store, [], quantity);
    }

    const editItemOrder = (id: number, item?: Item) => {
        navigate(`/editOrderItemPage/${item?._id}/${id}`)
    }

    const checkOut = () => {
        navigate('/checkOut')
    }

    return (
        <div className='cartContainer'>
            <Header back title={t('Cart')} whiteTheme backAction={() => navigate(-1)} />
            {!_.isEmpty(cartData) && (
                <div className='cartContainer-outletContainer'>
                    <div className='cartContainer-outletContainer-left'>
                        <div className='cartContainer-outletContainer-left-logo'>
                            <img src={outletData?.company?.main_image || require('../../assets/images/logo.png')} />
                        </div>
                    </div>
                    <div className='cartContainer-outletContainer-right'>
                        <div className='cartContainer-outletContainer-right-label'>
                            {t(`${outletData?.company?.display_name}`)}
                        </div>
                        <div className='cartContainer-outletContainer-right-method'>
                            <div className='cartContainer-outletContainer-right-method-label'>
                                {t(`${method}`)}
                            </div>
                            {(tableNumber && (method !== 'delivery')) && (
                                <div className='cartContainer-outletContainer-right-method-table'>
                                    {t(`${tableNumber}`)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!_.isEmpty(cartData) && (
                <div className='cartContainer-cartDetail'>
                    {_.map(cartData, (item, index) => (
                        <div key={index} className='cartContainer-cartDetail-item'>
                            <div className='cartContainer-cartDetail-item-inner'>
                                <div className='cartContainer-cartDetail-item-inner-store'>
                                    {t(`${item?.store?.display_name}`)}
                                </div>
                                <div className='cartContainer-cartDetail-item-inner-detail'>
                                    <div className='cartContainer-cartDetail-item-inner-detail-inner'>
                                        <div className='cartContainer-cartDetail-item-inner-detail-inner-detail'>
                                            <div className='cartContainer-cartDetail-item-inner-detail-inner-detail-name'>
                                                {t(`${item?.item?.name}`)}
                                            </div>
                                            <div className='cartContainer-cartDetail-item-inner-detail-inner-detail-price'>
                                                {item?.totalAmount.toFixed(2)}
                                            </div>
                                        </div>

                                        {_.map(item?.addOn, (option, index) => (
                                            <div key={index} className='cartContainer-cartDetail-item-inner-detail-inner-detail'>
                                                {subItemDisplay(option)}
                                            </div>
                                        ))}

                                    </div>
                                    <div className='cartContainer-cartDetail-item-inner-detail-bottom'>
                                        <div className='cartContainer-cartDetail-item-inner-detail-bottom-left'>
                                            <div className='cartContainer-cartDetail-item-inner-detail-bottom-left-icon'
                                                onClick={() => editItemOrder(item?.id, item?.item)}
                                            >
                                                <img src={editIcon} />
                                            </div>
                                            <div className='cartContainer-cartDetail-item-inner-detail-bottom-left-icon'
                                                onClick={() => deleteItem(item)}>
                                                <img src={deleteIcon} />
                                            </div>
                                        </div>
                                        <div className='cartContainer-cartDetail-item-inner-detail-bottom-right'>
                                            <QuantityButton borderColor={'#AEAEB2'} quantity={item?.quantity}
                                                increaseQuantity={() =>
                                                    increaseQuantity(item)}
                                                decreaseQuantity={() => decreaseQuantity(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!_.isEmpty(cartData) ? (
                <div className='cartContainer-totalBill'>
                    <div className='cartContainer-totalBill-item'>
                        <div className='cartContainer-totalBill-item-left'>
                            {t('Subtotal')}
                        </div>
                        <div className='cartContainer-totalBill-item-right'>
                            RM {totalAmount.toFixed(2)}
                        </div>
                    </div>
                    <div className='cartContainer-totalBill-item'>
                        <div className='cartContainer-totalBill-item-left'>
                            {t('Service fee')} 10%
                        </div>
                        <div className='cartContainer-totalBill-item-right'>
                            RM {serviceTax.toFixed(2)}
                        </div>
                    </div>
                    <div className='cartContainer-totalBill-item noBottom'>
                        <div className='cartContainer-totalBill-item-left'>
                            {t('SST')} 6%
                        </div>
                        <div className='cartContainer-totalBill-item-right'>
                            RM {ssl.toFixed(2)}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='cartContainer-empty'>
                    <div className='cartContainer-empty-notFound'>
                        <img src={require('../../assets/images/Plate.png')} />
                        <div className='cartContainer-empty-title'>
                            {t('Your cart is empty')}
                        </div>
                        <div className='cartContainer-empty-desc'>
                            {t('The items you add will appear here.')}
                        </div>
                    </div>
                </div>
            )}
            {!_.isEmpty(cartData) ? (
                <div className='cartContainer-cartBar'>
                    <div className='cartContainer-cartBar-top'>
                        <div className='cartContainer-cartBar-top-left'>
                            <div className='cartContainer-cartBar-top-left-label'>
                                {t('Total')}
                            </div>
                            <div className='cartContainer-cartBar-top-left-labelTax'>
                                {t('(incl. tax)')}
                            </div>
                        </div>
                        <div className='cartContainer-cartBar-top-right'>
                            <div className='cartContainer-cartBar-top-right-price'>
                                RM {payAmount.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className='cartContainer-cartBar-bottom'>
                        <div className='cartContainer-cartBar-bottom-left'>
                            <div className='cartContainer-cartBar-bottom-left-btn' onClick={() =>
                                navigate('/store')}>
                                {t('Add items')}
                            </div>
                        </div>
                        <div className='cartContainer-cartBar-bottom-right'>
                            <Button text={`Checkout`} action={() => {
                                checkOut()
                            }} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='cartContainer-cartBar'>
                    <Button text={`Browse menu`} action={() => {
                        navigate('/store')
                    }} />
                </div>
            )}
        </div>
    );
};

export default CartPage;
