import { createContext, useContext, useState, ReactNode } from 'react';

interface LoadingContextType {
  loadingStatus: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loadingStatus, setLoadingStatus] = useState(false);

  const showLoading = () => {
    setLoadingStatus(true);
  };

  const hideLoading = () => {
    setLoadingStatus(false);
  };



  return (
    <LoadingContext.Provider value={{ loadingStatus, showLoading, hideLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
