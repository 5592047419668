
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { reverseGeocode } from '../../utils/mapboxUtils';
import { useCart } from '../../contexts/CartContent';
import { useLoading } from '../../contexts/LoadingContent';
import SearchLocationModal from '../SearchLocationModal';
import rightBlue from '../../assets/icons/rightBlue.svg';

const DeliveryComponents: React.FC = () => {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [modal, setModal] = useState<boolean>(false);
  // const [error, setError] = useState<string | null>(null);
  const { selectedAddress, pickAddress } = useCart();
  const { showLoading, hideLoading } = useLoading() || {};
  const { t } = useTranslation();

  useEffect(() => {
    console.log('selectedAddress', selectedAddress)
    if (_.isEmpty(selectedAddress)) {
      getCurrentLocation();
    }
  }, [selectedAddress]);


  const getCurrentLocation = () => {
    showLoading?.();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          console.log(position)
          hideLoading?.();
        },
        (error) => {
          hideLoading?.();
          if (error.code === error.PERMISSION_DENIED) {
            alert('Location access is denied. Please allow location access in your iPhone settings.');
          } else {
            console.error('Geolocation error:', error.message);
            alert('Unable to retrieve location. Please ensure you have a stable network connection and GPS signal.');
          }
          setLatitude(null);
          setLongitude(null);
        }
      );
    } else {
      hideLoading?.();
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleFindAddress = async (latitude: number, longitude: number) => {
    if (latitude !== 0 && longitude !== 0) {
      showLoading?.();
      const result = await reverseGeocode(latitude, longitude);
      console.log('result', result);
      const currentLocation = {
        place_name: result,
        text: result,
        lat: latitude,
        long: longitude
      }
      pickAddress(currentLocation);
      hideLoading?.();
    }
  };

  useEffect(() => {
    if (latitude && longitude) {
      handleFindAddress(latitude, longitude);
    }
  }, [latitude, longitude]);


  return (
    <>
      <div className='deliveryContainer-currentAddress' onClick={() => setModal(true)}>
        <div className='deliveryContainer-currentAddress-logo'>
          <img src={require('../../assets/images/marker.png')} />
        </div>
        <div className='deliveryContainer-currentAddress-detail'>
          {selectedAddress?.place_name}
        </div>
        <div className='deliveryContainer-currentAddress-more' >
          <img src={rightBlue} />
        </div>
      </div>
      <div className='deliveryContainer-addNote'>
        <div className='deliveryContainer-addNote-container'>
          <div className='deliveryContainer-addNote-container-left'>
            <div className='deliveryContainer-addNote-container-left-text'>
              {t('Add address details and delivery instructions')}
            </div>
          </div>
          <div className='deliveryContainer-addNote-container-right'>
            {t('Edit')}
          </div>
        </div>
      </div>
      <SearchLocationModal setModal={setModal} modal={modal} getCurrentLocation={getCurrentLocation} />
    </>
  )

};

export default DeliveryComponents;