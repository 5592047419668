import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import accountRightIcon from '../../assets/icons/accountRight.svg';

interface AccountItemProps {
  label?: string;
  icon?: string;
  color?: string;
}

// Functional component with props type
const AccountItem: React.FC<AccountItemProps> = ({
  label,
  icon,
  color
}) => {
  const { t } = useTranslation();

  return (
    <div className='accountItem'>
      <div className='accountItem-inner'>
        <div className='accountItem-inner-left'>
          <div className='accountItem-inner-left-icon'>
            <img src={icon} />
          </div>
          <div className='accountItem-inner-left-label' style={{ color: color }}>
            {t(`${label}`)}
          </div>
        </div>
        <div className='accountItem-inner-right'>
          <img src={accountRightIcon} />
        </div>
      </div>
    </div>
  );
};

export default AccountItem;