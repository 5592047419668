// import {
//   ApolloClient,
//   InMemoryCache,
//   from,
//   HttpLink,
//   split,
// } from '@apollo/client';
// import { RetryLink } from '@apollo/client/link/retry';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { setContext } from '@apollo/client/link/context';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { onError } from '@apollo/client/link/error';
// import Config from './config';

// const GRAPHQL_HTTP_ENDPOINT = Config.GRAPHQL_HTTP;
// const GRAPHQL_WS_ENDPOINT = Config.GRAPHQL_WS;

// export default function createApolloClient(idToken?: string) {
//   // WebSocket Link
//   const wsLink = new WebSocketLink({
//     uri: GRAPHQL_WS_ENDPOINT,
//     options: {
//       lazy: true,
//       reconnect: true,
//       connectionParams: {
//         authorization: idToken || '',
//       },
//     },
//   });

//   // Http Link
//   const httpLink = new HttpLink({
//     uri: GRAPHQL_HTTP_ENDPOINT,
//     credentials: 'include',
//   });

//   // Split Link: Use WebSocket for subscriptions, HTTP for queries/mutations
//   const splitLink = split(
//     ({ query }) => {
//       const definition = getMainDefinition(query);
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       );
//     },
//     wsLink,
//     httpLink
//   );

//   // Retry Link: Retry logic
//   const retryLink = new RetryLink({
//     attempts: { max: 6 }, // Retry up to 6 times for non-mutation operations
//   });

//   // Error Link: Handle GraphQL and Network errors
//   const errorLink = onError(({ graphQLErrors, networkError }) => {
//     if (graphQLErrors) {
//       graphQLErrors.forEach(({ message, locations, path }) =>
//         console.error(
//           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//         )
//       );
//     }
//     if (networkError) {
//       console.error(`[Network error]: ${networkError}`);
//     }
//   });

//   // Auth Link: Attach Authorization Token
//   const authLink = setContext((_, { headers }) => ({
//     headers: {
//       ...headers,
//       authorization: idToken || '',
//     },
//   }));

//   // Apollo Client
//   return new ApolloClient({
//     link: from([errorLink, retryLink, authLink, splitLink]),
//     cache: new InMemoryCache(),
//     defaultOptions: {
//       watchQuery: {
//         fetchPolicy: 'cache-and-network',
//         errorPolicy: 'all',
//       },
//       query: {
//         fetchPolicy: 'no-cache',
//         errorPolicy: 'all',
//       },
//     },
//   });
// }


import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Config from './config';

const GRAPHQL_HTTP_ENDPOINT = Config.GRAPHQL_HTTP;

export default function createApolloClient(idToken?: string) {
  const httpLink = new HttpLink({
    uri: GRAPHQL_HTTP_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: idToken ? `Bearer ${idToken}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
}