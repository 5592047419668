import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Store } from '../../types/dataType';
import rightIcon from '../../assets/icons/right.svg';

interface ContainerProps {
    children: React.ReactNode;
    data: Store;
    action: () => void
}

const ProductBodyContainer: React.FC<ContainerProps> = ({ children, data, action }) => {
    const { t } = useTranslation();


    return (
        <div className='ProductBodyContainer-content'>
            <div className='ProductBodyContainer-content-inner-header'>
                <div className='ProductBodyContainer-content-header'>
                    <div className='ProductBodyContainer-content-header-inner'>
                        <div className='ProductBodyContainer-content-header-logo'>
                            <img src={data?.logo_image || require('../../assets/images/logo.png')} />
                        </div>
                        <div className='ProductBodyContainer-content-header-titleContainer'>
                            <div className='ProductBodyContainer-content-header-titleContainer-left'>
                                <div className='ProductBodyContainer-content-header-titleContainer-left-title'>
                                    {t(`${data?.display_name}`)}
                                </div>
                                <div className='ProductBodyContainer-content-header-titleContainer-left-type'>
                                    {t(`${data?.description}`)}
                                </div>
                            </div>
                            <div className='ProductBodyContainer-content-header-titleContainer-right' onClick={() => action()}>
                                <img src={rightIcon} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ProductBodyContainer-content-body'>
                {children}
            </div>
        </div>
    );
};

export default ProductBodyContainer;
