import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import QuantityButton from '../QuantityButton';
import { useCart } from '../../contexts/CartContent';
import { Item } from '../../types/dataType';

interface ProductContainerProps {
    item: Item;
    index: number;
    addItem: (item: Item) => void;
    removeItem: (item: Item) => void;
}


const ProductContainer: React.FC<ProductContainerProps> = ({
    item, index, addItem, removeItem
}) => {

    const { cart } = useCart();
    const { t } = useTranslation();

    const getItemQuantity = (itemId: string) => {
        const totalQuantity = cart.reduce((sum, cartItem) => {
            if (cartItem.item._id === itemId) {
                return sum + cartItem.quantity;
            }
            return sum;
        }, 0);

        return totalQuantity;
    };


    return (
        <div key={index} className='productContainer-content-item'>
            <div className='productContainer-content-item-inner'>
                <div className='productContainer-content-item-inner-left'>
                    <div className='productContainer-content-item-inner-left-name'>
                        {item?.name}
                    </div>
                    {/* <div className='productContainer-content-item-inner-left-type'>
                        {item?.category_list && item.category_list.length > 0
                            && item.category_list.map(cat => cat.display_name).filter(Boolean).join(' / ')
                        }
                    </div> */}
                    {item?.tags && (
                        <div className='productContainer-content-item-inner-left-type'>
                            {t(`${item?.tags}`)}
                        </div>
                    )}
                    <div className='productContainer-content-item-inner-left-price'>
                        {(item?.price ?? 0).toFixed(2)}
                    </div>
                </div>
                <div className='productContainer-content-item-inner-right'>
                    <img className='productContainer-content-item-inner-right-img' src={item?.main_image || require('../../assets/images/logo.png')} />
                    <div className='productContainer-content-item-inner-right-icon'>
                        <QuantityButton quantity={getItemQuantity(item?._id ?? '')} increaseQuantity={() => addItem(item)}
                            decreaseQuantity={() => removeItem(item)} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProductContainer;
