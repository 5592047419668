import React from 'react';
import './index.scss';
import CheckBox from '../../components/CheckBox';
import * as _ from 'lodash';


interface CheckBoxFormProps {
    data: string[];
    selectedData: string | null;
    setSelectedData: React.Dispatch<React.SetStateAction<string | null>>;
}

const CheckBoxForm: React.FC<CheckBoxFormProps> = ({
    data, selectedData, setSelectedData
}) => {

    return (
        <div className='checkBoxForm'>
            {_.map(data, (item, index) => (
                <div key={index} className='checkBoxForm-item' onClick={() => setSelectedData(item.toString())}>
                    <CheckBox
                        imageIcon={require(`../../assets/images/${item.toString()}.png`)}
                        text={item.toString()}
                        active={selectedData === item.toString()}
                    />
                </div>
            ))}
        </div>
    );
};

export default CheckBoxForm;
