import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import InputBox from '../InputBox';
import { Item, Store } from '../../types/dataType';
import ModalComponent from '../ModalComponent';
import ProductContainer from '../ProductContainer';
import { useCart } from '../../contexts/CartContent';
import SelectedProductDetailModal from '../../components/SelectedProductDetailModal';
import blackClose from '../../assets/icons/blackClose.svg';

interface SearchProductModalProps {
  modal: boolean;
  setModal: (value: boolean) => void;
  data?: Item[];
  store?: Store;
}


// Functional component with props type
const SearchProductModal: React.FC<SearchProductModalProps> = ({
  modal,
  setModal,
  data,
  store,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [openSelectedItemModal, setOpenSelectedItemDetailModal] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const [filteredItems, setFilteredItems] = useState<Item[] | null>(null);
  const [allItems, setAllItems] = useState<Item[] | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToCart } = useCart();

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [modal]);

  useEffect(() => {
    if (data) {
      setFilteredItems(data);
      setAllItems(data);
    }
  }, [data]);

  useEffect(() => {
    if (store) {
      setSelectedStore(store);
    }
  }, [store]);


  const closeModal = () => {
    setModal(false);
    document.body.style.overflow = '';
  };




  const handleSearchChange = (searchValue: string) => {
    setInputValue(searchValue.toLowerCase());
    if (allItems) {
      const filtered = allItems.filter(item => {
        try {
          const nameMatch = item.name?.toLowerCase().includes(searchValue.toLowerCase());

          const categoryMatch = item.category_list?.some(type => type?.display_name?.toLowerCase().includes(searchValue.toLowerCase()));

          const tagMatch = item.tags?.toLowerCase().includes(searchValue.toLowerCase())

          return nameMatch || categoryMatch || tagMatch;
        } catch (error) {
          return false;
        }
      });
      setFilteredItems(filtered);
    }
  };


  const openProductDetail = (product: Item) => {
    if (_.isEmpty(product?.option_group_list)) {
      setToCart(product, selectedStore, [], 1);
    } else {
      closeModal();
      navigate(`/productDetail/${product?._id}`)
    }
  }

  const openProductInCart = (product: Item) => {
    if (_.isEmpty(product?.option_group_list)) {
      setToCart(product, selectedStore, [], -1);
    } else {
      setSelectedItem(product);
      setOpenSelectedItemDetailModal(true)
    }
  }


  return (
    <>
      <ModalComponent modal={modal} setModal={setModal} fullHeight>
        <div className='searchProductModal-modal-header'>
          <div className='searchProductModal-modal-header-inner'>
            <div className='searchProductModal-modal-header-inner-icon' onClick={() => closeModal()}>
              <img src={blackClose} />
            </div>
            <div className='searchProductModal-modal-header-inner-text'>
              {t(`Search`)}
            </div>
          </div>
          <div className='searchProductModal-modal-header-input'>
            <InputBox deleteIcon searchIcon inputValue={inputValue}
              setInputValue={handleSearchChange} />
          </div>
        </div>
        <div className='searchProductModal-modal-body'>
          {!_.isEmpty(filteredItems) ? (
            <div className='searchProductModal-modal-body-result'>
              {_.map(filteredItems, (item, index) => (
                <div key={index} className='searchProductModal-modal-body-result-item'>
                  <ProductContainer item={item} index={index}
                    addItem={openProductDetail} removeItem={openProductInCart} />
                </div>
              ))}
            </div>
          ) : (
            <div className='searchProductModal-modal-body-noResult'>
              <div className='searchProductModal-modal-body-noResult-notFound'>
                <img src={require('../../assets/images/notFound.png')} />
                <div className='searchProductModal-modal-body-noResult-notFound-title'>
                  {t('We didn’t find a match')}
                </div>
                <div className='searchProductModal-modal-body-noResult-notFound-desc'>
                  {t('Try searching for something else instead.')}
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalComponent>
      <SelectedProductDetailModal modal={openSelectedItemModal}
        setModal={setOpenSelectedItemDetailModal} data={selectedItem} />
    </>
  );
};

export default SearchProductModal;
